<template>
    <div>
        <div v-if="success">
            <div class="container mx-auto my-10 px-4">
                <h1 class="h1 header text-3xl text-center my-6">Vielen Dank!</h1>
                <h2 class="h2 header text-xl text-center my-6">Wir werden uns schnellstmäglich bei Ihnen melden.</h2>
                <div class="flex justify-center">
                    <a href="/" class="btn">Zur Startseite</a>
                </div>
            </div>
        </div>
        <div class="container mx-auto px-4 w-full md:w-3/4 lg:w-1/2" v-else>
            <h1 class="text-center header-base">Datenerfassung</h1>
            <div class="form-group">
                <label for="name-input" class="input-label">Name</label>
                <input id="name-input" placeholder="Ihr Name" type="text" v-model="query.name"
                       v-bind:class="{ 'input-is-invalid': errors.name }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>
            <div class="form-group">
                <label for="email-input" class="input-label">E-Mail</label>
                <input id="email-input" placeholder="Ihre E-Mail" type="email" v-model="query.email"
                       v-bind:class="{ 'input-is-invalid': errors.email }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.email">{{ errors.email[0] }}</div>
            </div>
            <div class="form-group">
                <label for="phone-input" class="input-label">Telefonnummer</label>
                <input id="phone-input" placeholder="Ihre Telefonnummer" type="text" v-model="query.phone"
                       v-bind:class="{ 'input-is-invalid': errors.phone }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.phone">{{ errors.phone[0] }}</div>
            </div>
            <div class="form-group">
                <label for="car_model-input" class="input-label">Fahrzeugmodell</label>
                <input id="car_model-input" placeholder="z.B. Audi Q7 3.0 TDI" type="text" v-model="query.car_model"
                       v-bind:class="{ 'input-is-invalid': errors.car_model }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.car_model">{{ errors.car_model[0] }}</div>
            </div>
            <div class="form-group">
                <label for="mileage_purchased-input" class="input-label">km-Stand bei Kauf</label>
                <input id="mileage_purchased-input" placeholder="Gefahrene km bei Kauf" type="text"
                       v-model="query.mileage_purchased" v-bind:class="{ 'input-is-invalid': errors.mileage_purchased }"
                       class="input w-full">
                <div class="invalid-feedback" v-if="errors.mileage_purchased">{{ errors.mileage_purchased[0] }}</div>
            </div>
            <div class="form-group">
                <label for="mileage_current-input" class="input-label">Aktueller km-Stand</label>
                <input id="mileage_current-input" placeholder="Aktueller Kilometerstand" type="text"
                       v-model="query.mileage_current" v-bind:class="{ 'input-is-invalid': errors.mileage_purchased }"
                       class="input w-full">
                <div class="invalid-feedback" v-if="errors.mileage_current">{{ errors.mileage_current[0] }}</div>
            </div>
            <div class="form-group">
                <label for="price-input" class="input-label">Kaufpreis</label>
                <input id="price-input" placeholder="Kaufpreis in €" type="text" v-model="query.price"
                       v-bind:class="{ 'input-is-invalid': errors.price }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.price">{{ errors.price[0] }}</div>
            </div>
            <div class="form-group">
                <label class="input-label">Bestand zum Kaufzeitpunkt eine Verkehrsrechtsschutzversicherung?</label>
                <input type="radio" id="insurance_present-yes" value="true" v-model="query.insurance_present">
                <label for="insurance_present-yes" class="mr-4">Ja</label>
                <input type="radio" id="insurance_present-no" value="false" v-model="query.insurance_present">
                <label for="insurance_present-no">Nein</label>
                <div class="invalid-feedback" v-if="errors.insurance_present">{{ errors.insurance_present[0] }}</div>
            </div>
            <div class="form-group" v-if="query.insurance_present == 'true'">
                <label for="insurance_name-input" class="input-label">Name der Verkehrsrechtsschutzversicherung</label>
                <input id="insurance_name-input" placeholder="Name der Verkehrsrechtsschutzversicherung" type="text"
                       v-model="query.insurance_name" v-bind:class="{ 'input-is-invalid': errors.insurance_name }"
                       class="input w-full">
                <div class="invalid-feedback" v-if="errors.insurance_name">{{ errors.insurance_name[0] }}</div>
            </div>
            <div class="form-group">
                <label for="date_purchased-input" class="input-label">Kaufdatum</label>
                <input id="date_purchased-input" placeholder="z.B. Juni 2017" type="text" v-model="query.date_purchased"
                       v-bind:class="{ 'input-is-invalid': errors.date_purchased }" class="input w-full">
                <div class="invalid-feedback" v-if="errors.date_purchased">{{ errors.date_purchased[0] }}</div>
            </div>
            <div class="form-group">
                <label class="input-label">War das Update bei Kauf schon installiert?</label>
                <input type="radio" id="purchased_with_software_update-yes" value="true"
                       v-model="query.purchased_with_software_update">
                <label for="insurance_present-yes" class="mr-4">Ja</label>
                <input type="radio" id="purchased_with_software_update-no" value="false"
                       v-model="query.purchased_with_software_update">
                <label for="insurance_present-no">Nein</label>
                <div class="invalid-feedback" v-if="errors.purchased_with_software_update">{{
                    errors.purchased_with_software_update[0] }}
                </div>
            </div>
            <div class="form-group">
                <label class="input-label">Wurde das Auto bei einem Audi Vetragshändler gekauft?</label>
                <input type="radio" id="purchased_at_authorized_dealer-yes" value="true"
                       v-model="query.purchased_at_authorized_dealer">
                <label for="insurance_present-yes" class="mr-4">Ja</label>
                <input type="radio" id="purchased_at_authorized_dealer-no" value="false"
                       v-model="query.purchased_at_authorized_dealer">
                <label for="insurance_present-no">Nein</label>
                <div class="invalid-feedback" v-if="errors.purchased_at_authorized_dealer">{{
                    errors.purchased_at_authorized_dealer[0] }}
                </div>
            </div>
            <div class="form-group flex justify-end items-center">
                <div class="mr-4" v-if="saving">
                    <pulse-loader color="#001A3D"></pulse-loader>
                </div>
                <button class="btn" @click="saveQuery()" :disabled="!!saving">
                    Senden
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['referrer'],
        data: function () {
            return {
                query: {referrer: this.referrer},
                errors: [],
                saving: false,
                success: false,
            }
        },
        methods: {
            saveQuery: function () {
                this.saving = true
                this.$http['post']('/queries', {query: this.query}).then(response => {
                    console.log(response)
                    this.success = true
                    this.errors = []
                    this.saving = false
                }, response => {
                    console.log(response)
                    this.saving = false
                    this.success = false
                    this.errors = []
                    if (response.status = 422) {
                        this.errors = response.body;
                    }
                })
            },
        },
    }
</script>