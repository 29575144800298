<template>
    <div class="">
        <div class="tab__header">
            <a href="#" class="tab__link p-4 block hover:font-bold no-underline text-sommerfeld-primary flex justify-start" @click.prevent="active = !active">
                <div>
                    <span class="down-Arrow mr-4 hover:no-underline text-sommerfeld-lighter" v-show="!active">&#9660;</span>
                    <span class="up-Arrow mr-4 hover:no-underline text-sommerfeld-lighter" v-show="active">&#9650;</span>
                </div>
                <div>
                    <span class="lg:text-xl text-lg">{{title}}</span>
                </div>
            </a>
        </div>
        <div class="tab__content py-6 px-12 p-base" v-show="active"><slot /></div>
    </div>
</template>

<script>
export default {
    props: [
        'title'
    ],
    data() {
        return {
            active: false,
        }
    },
    methods: {

    },

    mounted() {
        // console.log('Accordion mounted.');
    }
}
</script>
