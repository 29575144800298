import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource'

Vue.use(VueResource);

Vue.component('accordion', require('../components/accordion.vue').default);
Vue.component('calculadora', require('../components/calculadora.vue').default);
Vue.component('query-form', require('../components/query_form.vue').default);
Vue.component('modal', require('../components/modal.vue').default);
Vue.component('news', require('../components/news.vue').default);

Vue.component('pulse-loader', require('vue-spinner/src/PulseLoader.vue').default);

Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    data: {
      showContactModal: false,
    }
  })
})

//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
