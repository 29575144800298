<template>
    <div class="">
        <form id="calculadoraForm" @submit="checkForm" action="#" method="post">
            <div class="mb-8">
                <label for="price_input" class="text-sommerfeld-lighter uppercase">Kaufpreis</label>
                <input
                        id="price_input"
                        v-model="price"
                        type="number"
                        name="price"
                        placeholder="Kaufpreis"
                        required
                        min="1"
                        max="10000000"
                        class="input w-full"
                >
            </div>

            <div class="mb-8">
                <label for="km_buyed_input" class="text-sommerfeld-lighter uppercase">Km-Stand bei Kauf</label>
                <input
                        id="km_buyed_input"
                        v-model="kmBuyed"
                        type="number"
                        name="km_buyed"
                        placeholder="km bei Kauf"
                        required
                        min="1"
                        max="10000000"
                        class="input w-full"
                >
            </div>

            <div class="mb-8">
                <label for="km_now_input" class="text-sommerfeld-lighter uppercase">Km-Stand jetzt</label>
                <input
                        id="km_now_input"
                        v-model="kmNow"
                        type="number"
                        name="km_now"
                        placeholder="km jetzt"
                        required
                        min="1"
                        max="10000000"
                        class="input w-full"
                >
            </div>

<!--            <div>-->
<!--                <input-->
<!--                        type="submit"-->
<!--                        value="Submit"-->
<!--                >-->
<!--            </div>-->

            <div class="mb-8">
                <label for="current_value_input" class="text-sommerfeld-lighter uppercase">Rückabwicklungssumme</label>
                <input id="current_value_input" name="current_value" v-model="currentValue" disabled class="text-xl bg-white input w-full">
            </div>
            <div class="text-2xl flex justify-center">
<!--                <div v-show="this.rating == 5" class="text-green-600">-->
<!--                    <i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i>-->
<!--                </div>-->
<!--                <div v-show="this.rating == 4" class="text-green-600">-->
<!--                    <i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i>-->
<!--                </div>-->
                <div v-show="this.rating == 3" class="text-green-600">
                    <i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i><i class="fas fa-euro-sign mx-1"></i>
                </div>
                <div v-show="this.rating == 2" class="text-yellow-600">
                    <i class="far fa-meh mx-1"></i>Nur mit RSV
                </div>
                <div v-show="this.rating == 1" class="text-red-600">
                   dafür gibt es kein Geld
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: [],
    data() {
        return {
            valid: false,
            price: null,
            kmBuyed: null,
            kmNow: null,
        }
    },
    computed: {
        currentValue: function () {
            if (this.formValid()) {
                let drivenKm = this.kmNow - this.kmBuyed;
                let kmToGo = Math.max(300000 - drivenKm, 0);
                let pricePerKm = this.price / kmToGo;
                let lostValue = pricePerKm * drivenKm;

                return Math.round(this.price - lostValue);
            } else {
                return null;
            }
        },
        rating: function () {

            if (this.currentValue > 25000) {
                return 3;
            } else if (this.currentValue > 16000) {
                return 2;
            } else if (this.currentValue > 1) {
                return 1;
            }
            return 0;
        }
    },
    methods: {
        checkForm: function (e) {
            e.preventDefault();
        },
        formValid: function() {
            return (parseInt(this.price) > 0) && (parseInt(this.kmBuyed) > 0) && (parseInt(this.kmNow) > 0);
        },
    },

    mounted() {
    }
}
</script>
