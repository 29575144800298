<template>
    <span class="modal-backdrop flex justify-center items-start fixed inset-0 modal-bg z-50" style="overflow: scroll;"
      v-show="show">
        <div class="flex flex-col bg-white shadow-xl">
            <header class="p-6">
                <div class="flex justify-end items-center w-full">
                        <button type="button" class="text-blue-dark font-bold text-xl" @click="close">
                            <i class="far fa-window-close"></i>
                        </button>
                </div>
            </header>
            <section class="p-8">
                <slot></slot>
            </section>
            <footer class="my-2 flex justify-center">
                <a @click="close" class="link text-sm">
                    <i class="far fa-window-close"></i> Fenster schließen
                </a>
            </footer>
        </div>
    </span>
</template>

<script>
    export default {
        props: ['show'],
        methods: {
            close: function () {
                this.$emit('close');
            }
        },
        mounted: function () {
            document.addEventListener("keydown", (e) => {
                if (this.show && e.keyCode === 27) {
                    this.close();
                }
            });
        }
    }
</script>
